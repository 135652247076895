import {
  SubButton as Button,
  TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { instructionImgSrc } from '../../../lib/instructionImgSrc';
import { useEventId } from '../../../redux/selectors/gameSelectors';
import CommonBG from '../../uiElements/CommonBG';

interface FinishedProps {
  stage: number;
  onBackToTop?: () => void;
}
const Finished: React.FC<FinishedProps> = props => {
  const eventId = useEventId();

  const toTop = React.useCallback(() => {
    window.location.href =
      'https://specc-dev.riddler.co.jp/events/' + eventId + '/stages';
  }, [eventId]);

  return (
    <CommonBG>
      <CWrapper>
        <VideoWrapper>
          <video controls>
            <source
              src={instructionImgSrc[props.stage].video_end}
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <Wrapper>
          <Text>
            <p>ステージ{props.stage}は終了です。お疲れさまでした。</p>
            <TopButton onClick={() => toTop()}>ステージ選択へ</TopButton>
          </Text>
        </Wrapper>
      </CWrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 4rem;
`;

const CWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

const Text = styled(TextWindow).attrs({
  bracket: true,
})`
  width: 60rem;
  max-width: 90vw;
  margin: 0 auto 0;
`;

const TopButton = styled(Button).attrs({
  variant: 'primary',
  size: 'large',
  color: 'positive',
})`
  margin: 2rem auto 1.4rem;
  display: block;
`;

const VideoWrapper = styled.div`
  justify-content: center;

  width: 900px;
  height: 506px;
  margin: 0 auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    height: 100%;
    margin-bottom: -3px;
  }
`;

export default Finished;

import { useCTSignIn } from '@riddler-co-jp/specc-auth-client';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useMatch } from 'react-router-dom';

import { useStartTimesEffect } from '../../api/stages';
import { appActions } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import { app } from '../..';
import Stage4 from './stagePages/stage4/Stage4';

interface EventRouterProps {}

export const EventsRouter: React.FC<EventRouterProps> = () => {
  const match = useMatch('/events/:eventId/*');
  useStartTimesEffect();
  const dispatch = useDispatch();

  const [signedIn, signInLoading] = useCTSignIn(
    app,
    'energy',
    match?.params.eventId,
    '1',
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );

  React.useEffect(() => {
    if (signInLoading) {
      dispatch(appActions.setLoadingState({ visible: true }));
    } else {
      dispatch(appActions.setLoadingState({ visible: false }));
    }
  }, [dispatch, signInLoading]);

  const user = useCurrentUser();
  console.log('user', user);

  /*
  if (eventLoading) {
    return null;
  }
  if (!eventAvailable) {
    return <Navigate to='/events' />;
  }*/

  console.log(match);
  return (
    <Routes>
      <Route path='/:eventId' element={<Stage4 />} />
      <Route path='/:eventId/*' element={<Stage4 />} />
    </Routes>
  );
};

export default EventsRouter;

import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { StageId } from '../../api/stages';

export const useStageState = (props: { stageId: StageId }) =>
  useSelector(state => ({
    finished: state.game.hasStageFinished[props.stageId],
  }));
export const useLocalStage4Penalty = () =>
  useSelector(state => state.game.stage4Penalty);
export const useStagesFinished = () =>
  useSelector(state => state.game.hasStageFinished);
export const useEventId = () =>
  useMatch('/events/:eventId/*')?.params.eventId ?? null;
export const useRecordedStartTimes = () =>
  useSelector(state => state.game.recordedStageStartTimes);
export const useRecordedStartTime = (stageId: StageId) =>
  useSelector(state => state.game.recordedStageStartTimes[stageId]);
export const useStageManagerState = () =>
  useSelector(state => state.game.stageManagerState);

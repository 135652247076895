import {
  Timestamp,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { timeActions } from '../redux/actions/timeActions';
import { useCurrentUser } from '../redux/selectors/authSelectors';
import { db } from '..';

type SnapshotData =
  | {
      timestamp: Timestamp;
    }
  | undefined;

export const useServerTimeOffsetEffect = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  useEffect(() => {
    // if (user == null) return;
    const ref = doc(db, 'version/1/dummy_for_time_get', user?.uid ?? 'userId');

    setDoc(ref, {
      timestamp: serverTimestamp(),
    }).then(() => {
      onSnapshot(ref, snapshot => {
        const timestamp = (snapshot.data() as SnapshotData)?.timestamp;
        const offset =
          timestamp != null
            ? (timestamp.toMillis() - Date.now()) / 1000
            : undefined;
        dispatch(timeActions.setServerTimeOffset(offset));
      });
    });
  }, [dispatch, user]);
};

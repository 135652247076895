import { DocumentReference, Timestamp, doc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useDispatch } from 'react-redux';

import { UserRecords } from '../api/firestoreTypes';
import { STAGE_TIME_LIMIT } from '../components/eventProvider/stageProviders/StageManager';
import { gameActions } from '../redux/actions/gameActions';
import { useCurrentUser } from '../redux/selectors/authSelectors';
import { useEventId } from '../redux/selectors/gameSelectors';
import { useGetServerTime } from '../redux/selectors/timeSelectors';
import { db } from '..';

export const stageIds = [1, 2, 3, 4, 5] as const;
export type StageId = typeof stageIds[number];

type StartTimestamps = Partial<Record<StageId, Timestamp>>;
type StartTimes = Partial<Record<StageId, number>>;

export const useStartTimesEffect = () => {
  const user = useCurrentUser();
  const eventId = useEventId();
  const dispatch = useDispatch();
  const getServerTime = useGetServerTime();
  const [startTimestamps, loading] = useDocumentData<UserRecords>(
    /*user !== null && */ eventId !== null
      ? (doc(
          db,
          'version/1/event_user_records',
          eventId,
          'users',
          user?.uid ?? 'userId'
        ) as DocumentReference<UserRecords>)
      : undefined
  );

  useEffect(() => {
    if (loading) return;
    const startTimes: StartTimes = {};

    const table = {
      1: startTimestamps?.startTimes1,
      2: startTimestamps?.startTimes2,
      3: startTimestamps?.startTimes3,
      4: startTimestamps?.startTimes4,
      5: startTimestamps?.startTimes5,
    };
    stageIds.forEach(key => {
      const timestamp = table[key];
      if (timestamp === undefined || timestamp === null) {
        if (key !== 5) {
          dispatch(
            gameActions.setHasStageFinished({ stage: key, finished: false })
          );
        }

        return;
      }
      const startTime = timestamp!.toMillis() / 1000;
      startTimes[key] = startTime;
      if (key !== 5 && startTime + STAGE_TIME_LIMIT < getServerTime()) {
        dispatch(
          gameActions.setHasStageFinished({ stage: key, finished: true })
        );
      }
    });
    dispatch(gameActions.setRecordedStageStartTimes(startTimes));
  }, [startTimestamps, loading, dispatch, getServerTime]);
};
